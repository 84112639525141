<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            <CRow>
              <CCol md="4"> Laporan Realisasi </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <div class="row">
              <div class="col-md-10">
                <div class="row">
                  <div class="col-md-3">
                    <div class="form-group">
                      <b for="">Perusahaan</b>
                      <v-select
                        :options="list_perusahaan"
                        label="p_nama"
                        v-model="perusahaan_selected"
                        :value="$store.myValue"
                      ></v-select>
                    </div>
                  </div>
                  <div class="col-md">
                    <div class="form-group">
                      <b for="">Tgl Dari</b>
                      <vuejs-datepicker
                        input-class="form-control no-readonly"
                        v-model="start"
                        format="dd MMM yyyy"
                      ></vuejs-datepicker>
                    </div>
                  </div>
                  <div class="col-md">
                    <div class="form-group">
                      <b for="">Tgl Sampai</b>
                      <vuejs-datepicker
                        input-class="form-control no-readonly"
                        v-model="end"
                        format="dd MMM yyyy"
                      ></vuejs-datepicker>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <b for="">Tipe Permohonan</b>
                      <v-select
                        :options="filter_tipe"
                        :reduce="(label) => label.value"
                        label="label"
                        v-model="is_pemasukan"
                        :value="$store.myValue"
                      ></v-select>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <b for="">Module Permohonan</b>
                      <v-select
                        :options="filter_module"
                        :reduce="(label) => label.value"
                        label="label"
                        v-model="prs_kategori"
                        :value="$store.myValue"
                      ></v-select>
                    </div>
                  </div>
                  <!-- <div class="col-md-4">
                                            <div class="form-group">
                                                <b for="">Status</b> 
                                                <v-select :options="filter_status" :reduce="label => label.value" label="label" v-model="status":value="$store.myValue"></v-select>
                                            </div> 
                                        </div> -->
                </div>
              </div>
            </div>
            <div class="row">
              <div
                class="col-md-5 d-flex justify-content-start"
                style="margin-bottom: 10px; gap: 10px"
              >
                <div class="btn btn-sm btn-primary" v-on:click="filterData()">
                  <i class="fa fa-filter"></i> Filter
                </div>

                <div class="btn btn-sm btn-success" v-on:click="getExcel">
                  <i class="fa fa-download"></i> Excel
                </div>

                <div
                  class="btn btn-sm btn-danger"
                  v-on:click="modalFilterPDF = true"
                >
                  <i class="fa fa-download"></i> {{ buttonLabel }}
                </div>
              </div>
            </div>
            <CRow>
              <CCol sm="12">
                <CRow>
                  <CCol sm="12">
                    <table class="table table-sm table-bordered">
                      <thead class="bg-dark text-white">
                        <tr>
                          <th>No</th>
                          <th>NIB</th>
                          <th>Perusahaan</th>
                          <th>NPWP</th>
                          <th>Nomor SIP</th>
                          <th>Nomor Realisasi</th>
                          <th>Tanggal Pengajuan</th>
                          <th>Aksi</th>
                        </tr>
                      </thead>
                      <template>
                        <tbody v-if="reslistrealisasi.length != 0">
                          <template>
                            <tr v-if="loadingTable">
                              <td colspan="7">
                                <i class="fa fa-spin fa-refresh"></i> Loading...
                              </td>
                            </tr>
                            <tr
                              v-for="(
                                listrealisasi, key_realisasi
                              ) in reslistrealisasi"
                              v-else
                              v-bind:key="key_realisasi"
                            >
                              <td>
                                {{ key_realisasi + 1 }}
                                <!-- <template>
                                                                        <div v-if="key_realisasi+1 == 10">
                                                                            {{curent_page}}0
                                                                        </div>
                                                                        <div v-else>
                                                                            {{(curent_page-1 != 0)?curent_page-1:''}}{{key_realisasi+1}}
                                                                        </div>
                                                                    </template> -->
                                                                </td>
                                                                <td>{{listrealisasi.nib}}</td>
                                                                <td>{{listrealisasi.p_nama}}</td>
                                                                <td>{{listrealisasi.p_npwp}}</td>
                                                                <td>{{listrealisasi.prs_nomor_sk}}</td>
                                                                <td>{{listrealisasi.prs_nomor}}</td>
                                                                <td>{{listrealisasi.prs_tanggal}}</td>
                                                                <td>
                                                                    <router-link :to="{name: 'DetailRealisasi', params: { idrealisasi: listrealisasi.realisasi_id }}" class="btn btn-info btn-sm"><i class="fa fa-eye"></i> Detail</router-link> 

                                                                    
                                                                </td>
                                                                <!-- <td>{{listrealisasi.tanaman.t_nama}}</td>
                                                                <td>{{listrealisasi.tanaman.t_nama_latin}}</td>
                                                                <td>{{listrealisasi.prsb_jumlah}} {{listrealisasi.satuan.s_nama}} </td>
                                                                <td>{{listrealisasi.prsb_jumlah_realisasi}} {{listrealisasi.satuan.s_nama}}</td> -->
                                                            </tr>
                                                        </template>
                                                    </tbody>
                                                    <tbody v-else>
                                                        <template>
                                                            <tr v-if="loadingTable">
                                                                <td colspan="6"><i class="fa fa-spin fa-refresh"></i> Loading...</td>
                                                            </tr>
                                                            <tr v-else>
                                                                <td colspan="7">Data tidak tersedia</td>
                                                            </tr>
                                                        </template>
                                                    </tbody>
                                                </template>
                                            </table>
                                            <nav aria-label="pagination">
                                                <ul class="pagination justify-content-start">
                        <!-- <li
                          v-for="(page, key_page) in paging"
                          v-bind:key="key_page"
                          v-bind:class="{ active: page.active }"
                          class="page-item"
                        >
                          <div
                            v-on:click="toPage(page.url)"
                            class="page-link c-page-link-number"
                          >
                            <div v-html="page.label"></div>
                          </div>
                        </li> -->
                        <li
                          v-for="(page, key_page) in paging"
                          :key="key_page"
                          :class="[
                            'page-item',
                            { active: page.active, disabled: !page.url },
                          ]"
                        >
                          <a
                            href="#"
                            @click.prevent="page.url && toPage(page.url)"
                            class="page-link"
                          >
                            <span v-html="page.label"></span>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            <br />
            <!-- form Filter PDF -->
            <template>
              <div>
                <CModal
                  title="Filter data"
                  color="btn-primary"
                  :show.sync="modalFilterPDF"
                  size="md"
                >
                  <CRow>
                    <CCol md="12">
                      <div class="form-group">
                        <b for="">Tgl Dari</b>
                        <vuejs-datepicker
                          input-class="form-control no-readonly"
                          v-model="start_pdf"
                          format="dd MMM yyyy"
                        ></vuejs-datepicker>
                      </div>
                      <div class="form-group">
                        <b for="">Tgl Sampai</b>
                        <vuejs-datepicker
                          input-class="form-control no-readonly"
                          v-model="end_pdf"
                          :disabled-dates="disabledDates"
                          format="dd MMM yyyy"
                        ></vuejs-datepicker>
                      </div>
                    </CCol>
                  </CRow>
                  <template #footer>
                    <CButton @click="modalFilterPDF = false" color="secondary"
                      >Tutup</CButton
                    >
                    <CButton
                      v-on:click="getPDFLink()"
                      color="primary"
                      :disabled="isDownloading"
                      >{{ buttonLabel }}</CButton
                    >
                  </template>
                </CModal>
              </div>
            </template>

            <!-- form Filter PDF selesai -->
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<script>
    const datalaporan = [];
    export default {
        name: "AddLaporanPemohonRealisasi",
        components: {
            vuejsDatepicker
        },
        data() {
            return {
                reslistrealisasi: datalaporan,
                paging: [],
                show_data_to: "",
                show_data_from: "",
                total_data: "",
                curent_page: '',
                loadingTable: true,
                session_data: JSON.parse(this.session),
                is_pemasukan: "",
                start: "",
                end: "",
                status: "",
                excel_url:"",
                list_perusahaan:"",
                perusahaan_selected:"",
                filter_status: [
                    {
                        value: "proses",
                        label: "Proses Verifikasi",
                    },
                    {
                        value: "menunggu perbaikan",
                        label: "Menunggu Perbaikan",
                    },
                    {
                        value: "selesai",
                        label: "Selesai",
                    },
                    {
                        value: "dibatalkan",
                        label: "Dibatalkan",
                    },
                ],
                prs_kategori: [],
                filter_module: [
                    {
                        value: "Hortikultura",
                        label: "Hortikultura",
                    },
                    {
                        value: "Sumber Daya Genetik",
                        label: "Sumber Daya Genetik",
                    },
                    {
                        value: "Tanaman Pangan",
                        label: "Tanaman Pangan",
                    },
                    {
                        value: "Perkebunan",
                        label: "Perkebunan",
                    },
        {
          value: "Benih Tanaman Pakan Ternak",
          label: "Benih Tanaman Pakan Ternak",
        },
                ],
                filter_tipe: [
                    {
                        value: "1",
                        label: "Pemasukkan Benih Tanaman",
                    },
                    {
                        value: "0",
                        label: "Pengeluaran Benih Tanaman",
                    },
      ],
      // for filter pdf
      buttonLabel: "Export PDF",
      isDownloading: false,
      modalFilterPDF: false,
      start_pdf: "",
      end_pdf: "",
      disabledDates: {
        to: new Date(new Date().setDate(new Date().getDate() - 1)),
        from: new Date(8640000000000000),
        customPredictor: (date) => {
          if (!this.start_pdf) {
            return false;
          }
          const start = new Date(this.start_pdf);
          const end = new Date(this.start_pdf);
          end.setDate(start.getDate() + 31);

          return date < start || date > end;
        },
      },
            };
        },
        methods: {
            toPage(url) {
                this.loadingTable = true;
                if (!url) {
                    url = this.apiLink + "api/permohonan_realisasi/permohonan_all";
                }
               axios
                .get(url, {
                    params: {
                        nib: this.perusahaan_selected?.nib,
                    },
                    headers: {
                        Authorization: "Bearer " + this.access_token,
                    },
                })
                .then((response) => {
                    var res_realisasi = response.data;
                    var res_realisasi_data = res_realisasi.data;
                    // console.log(res_realisasi_data);
                    if (res_realisasi.meta.code != "200") {
                        Swal.fire({
                            icon: "error", 
                            title: "Oops...",
                            text: res_realisasi.data.message,
                        });
                    } else {
                        // let raw_realisasi = [];
                        // $.each(res_realisasi_data, function(index_realisasi, val_realisasi) {
                        //      $.each(val_realisasi.permohonan_realisasi_benih, function(index_benih, val_benih) {
                        //         val_benih.realisasi = val_realisasi;
                        //         raw_realisasi.push(val_benih);
                        //      });
                        // });
                        // console.log(raw_realisasi);
                       this.loadingTable = false;
                        this.reslistrealisasi = res_realisasi_data.data.data;
            // this.excel_url = "https://simpel2.pertanian.go.id/endpoint/public/api/excel_realisasi"+res_realisasi_data.excel;
            this.excel_url =
              this.apiLink + "api/excel_realisasi" + res_realisasi_data.excel;
                        this.paging = res_realisasi_data.data.links;
                        console.log(this.reslistrealisasi);
                        // this.paging = res_realisasi_data.data.links;
                        // this.show_data_from = res_realisasi_data.from;
                        // this.show_data_to = res_realisasi_data.to;
                        // this.total_data = res_realisasi_data.total;
                        // this.curent_page = res_realisasi_data.current_page;
                    }
                });
            },
            filterData() {
                this.loadingTable = false;
                // var numpage = url.replace(this.apiLink + "api/laporan/permohonan_benih?page=", "");
                let session_data = JSON.parse(this.session);
                axios
                .get(this.apiLink + "api/permohonan_realisasi/permohonan_all", {
                    params: {
                        nib: this.perusahaan_selected?.nib,
                        start: this.start,
                        end: this.end,
                        prs_kategori: this.prs_kategori,
                    },
                    headers: {
                        Authorization: "Bearer " + this.access_token,
                    },
                })
                .then((response) => {
                    var res_realisasi = response.data;
                    var res_realisasi_data = res_realisasi.data;
                    // console.log(res_realisasi_data);
                    if (res_realisasi.meta.code != "200") {
                        Swal.fire({
                            icon: "error", 
                            title: "Oops...",
                            text: res_realisasi.data.message,
                        });
                    } else {
                        // let raw_realisasi = [];
                        // $.each(res_realisasi_data, function(index_realisasi, val_realisasi) {
                        //      $.each(val_realisasi.permohonan_realisasi_benih, function(index_benih, val_benih) {
                        //         val_benih.realisasi = val_realisasi;
                        //         raw_realisasi.push(val_benih);
                        //      });
                        // });
                        // console.log(raw_realisasi);
                        this.loadingTable = false;
                        this.reslistrealisasi = res_realisasi_data.data.data;
            // this.excel_url =
            //   "https://simpel2.pertanian.go.id/endpoint/public/api/excel_realisasi" +
            this.excel_url =
              this.apiLink + "api/excel_realisasi" + res_realisasi_data.excel;
                        this.paging = res_realisasi_data.data.links;
                        console.log(this.reslistrealisasi);
                        // this.paging = res_realisasi_data.links;
                        // this.show_data_from = res_realisasi_data.from;
                        // this.show_data_to = res_realisasi_data.to;
                        // this.total_data = res_realisasi_data.total;
                        this.curent_page = res_realisasi_data.current_page;
                    }
                });
            },
            getExcel(){
                // alert(this.excel_url);
                // return false;
                var win = window.open(this.excel_url, '_blank');
                if (win) {
                    //Browser has allowed it to be opened
                    win.focus();
                } else {
                    //Browser has blocked it
                    alert('Please allow popups for this website');
                }
    },
    getPDFLink() {
      if (this.start_pdf === "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Silahkan masukan tanggal filter",
        });
        return;
      } else if (this.end_pdf === "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Silahkan masukan tanggal filter",
        });
        return;
      }

      this.buttonLabel = "Downloading data...";
      this.isDownloading = true;
      let fileName = "Laporan SIP.pdf";

      axios
        .get(this.apiLink + "api/permohonan_realisasi/pdf_realisasi", {
          params: {
            nib: this.perusahaan_selected?.nib,
            start: this.start_pdf,
            end: this.end_pdf,
            prs_kategori: this.prs_kategori,
          },
          responseType: "blob",
          headers: {
            Authorization: "Bearer " + this.access_token,
          },
        })
        .then((response) => {
          if (response.status != 200) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Data tidak ditemukan",
            });
          } else {
            const fileURL = window.URL.createObjectURL(
              new Blob([response.data])
            );
            const fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
            document.body.removeChild(fileLink);
          }
          this.buttonLabel = "Export PDF";
          this.isDownloading = false;
        })
        .catch((error) => {
          console.error("Error during PDF download", error);
          this.buttonLabel = "Export PDF";
          this.isDownloading = false;
        });
    },
        },
        beforeCreate() {
            let session_data = JSON.parse(this.session);
            axios
                .get(this.apiLink + "api/permohonan_realisasi/permohonan_all", {
                    params: {
                        // nib: session_data.nib,
                    },
                    headers: {
                        Authorization: "Bearer " + this.access_token,
                    },
                })
                .then((response) => {
                    var res_realisasi = response.data;
                    var res_realisasi_data = res_realisasi.data;
                    // console.log(res_realisasi_data);
                    if (res_realisasi.meta.code != "200") {
                        Swal.fire({
                            icon: "error", 
                            title: "Oops...",
                            text: res_realisasi.data.message,
                        });
                    } else {
                        // let raw_realisasi = [];
                        // $.each(res_realisasi_data, function(index_realisasi, val_realisasi) {
                        //      $.each(val_realisasi.permohonan_realisasi_benih, function(index_benih, val_benih) {
                        //         val_benih.realisasi = val_realisasi;
                        //         raw_realisasi.push(val_benih);
                        //      });
                        // });
                        // console.log(raw_realisasi);
                        this.loadingTable = false;
                        this.reslistrealisasi = res_realisasi_data.data.data;
          //   this.excel_url =
          //     "https://simpel2.pertanian.go.id/endpoint/public/api/excel_realisasi" +
          //     res_realisasi_data.excel;
          this.excel_url =
            this.apiLink + "api/excel_realisasi" + res_realisasi_data.excel;
                        this.paging = res_realisasi_data.data.links;
                        console.log(this.reslistrealisasi);
                        // this.show_data_from = res_realisasi_data.from;
                        // this.show_data_to = res_realisasi_data.to;
                        // this.total_data = res_realisasi_data.total;
                        // this.curent_page = res_realisasi_data.current_page;
                    }
                });
                // Get Master Tanaman
            axios.get(this.apiLink + "api/master/perusahaan", {
                params: {
                },
                headers: {
                    Authorization: "Bearer " + this.access_token,
                },
            })
            .then((response) => {
                var res_perusahaan = response.data;
                var res_perusahaan_data = res_perusahaan.data;
                if (res_perusahaan.meta.code != "200") {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: res_perusahaan.data.message
                    });
                }
                else{
                    this.list_perusahaan = res_perusahaan_data.master;
                    // console.log(res_tanaman_data.master);
                }
            });
        },
        mounted() {
            $('.no-readonly').prop('readonly', false);
  },
  watch: {
    start_pdf(val) {
      if (val) {
        const start = new Date(val);
        const end = new Date(start);
        end.setDate(start.getDate() + 31);

        this.disabledDates = {
          ranges: [
            {
              from: new Date(-8640000000000000),
              to: new Date(
                start.getFullYear(),
                start.getMonth(),
                start.getDate()
              ),
            },
            {
              from: new Date(end.getFullYear(), end.getMonth(), end.getDate()),
              to: new Date(8640000000000000),
            },
          ],
        };
      }
    },
  },
    };
</script>